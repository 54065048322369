import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/support.styles.scss";
import "../styles/general.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import { StaticImage } from "gatsby-plugin-image";
import SabineVideo from "../assets/videos/VID_20221023_173310_386.mp4";
import RolandVideo from "../assets/videos/IMG_0406.mp4";
import LugiaVideo from "../assets/videos/IMG_6042.mp4";
import ButtonComponent from "../components/ButtonComponent/ButtonComponent";
import { SupportItem } from "../utils/Support.types";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import { useWindowSize } from "../utils/useWindowSize";

const SupportPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "#f89a26",
      radius: 2500,
      weight: 8,
      top: -700,
      horiz: -700,
      active: true,
    },
  ]);

  const supportItems: SupportItem[] = [
    { name: "Sabine", video: SabineVideo },
    { name: "Roland", video: RolandVideo },
    { name: "Lugia", video: LugiaVideo },
  ];

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      default:
        break;
    }
  }, [width]);
  /**
   *
   * @param supportItem
   * @returns
   */
  const generateVideoSupportItem = (supportItem: SupportItem): JSX.Element => {
    return (
      <div
        className="support-item"
        key={`${supportItem.name}-${supportItem.video}`}
      >
        <div className="support-item--title">{supportItem.name}</div>
        <div className="support-item--video embed-container transparent">
          <video
            className="vimeo-video-iframe"
            // poster={poster}
            controls
          >
            <source src={supportItem.video} type="video/mp4" />
          </video>
        </div>
        <ButtonComponent
          invers
          title={t("page.support.videoButton", {
            replace: { name: supportItem.name },
          })}
        />
      </div>
    );
  };

  return (
    <LayoutComponent seo={{ title: t("page.support.title") }}>
      <div className="relative-parent">
        <StaticImage
          src="../assets/images/Header_Support.jpg"
          alt="Support Header"
          layout="fullWidth"
          placeholder="blurred"
        />
        <div
          className="filter-element"
          style={{ background: "#F8C106 0% 0% no-repeat padding-box" }}
        />
        <div className="title-video-box-wrapper">
          <div className="title-video-box-wrapper--title">
            90.10.
            <br />
            Support &amp; FAQ
          </div>
        </div>
      </div>

      <ContentRowComponent>
        <div className="text-content-wrapper">
          <div className="orange-box-title width-465">90.10. Support-Team</div>
          <div className="text-content-wrapper--headline">
            Lore ipsum dolore
          </div>
          <div className="text-content-wrapper--content">
            Du hast noch Fragen? sadipscing elitr, sed diam nonumy eirmod tempor
            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
            kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
            diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
            duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
            sanctus est Lorem ipsum dolor sit amet.
          </div>
        </div>
      </ContentRowComponent>

      <ContentRowComponent background="#F89B0640">
        <div className="video-support-wrapper">
          {supportItems.map((item) => generateVideoSupportItem(item))}
        </div>
      </ContentRowComponent>

      <ContentRowComponent>
        <div className="text-content-wrapper">
          <div className="orange-box-title width-465">90.10. Support</div>
          <div className="text-content-wrapper--headline">
            Lore ipsum dolore
          </div>
          <div className="text-content-wrapper--content">
            Buche hier einen Video-Anruf mit einem beliebigen Mitarbeiter aus
            unserem Support Team. sadipscing elitr, sed diam nonumy eirmod
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
            voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
            Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
            dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing
            elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
            justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
            takimata sanctus est Lorem ipsum dolor sit amet.
          </div>
          <ButtonComponent title={"Zur Terminbuchung"} invers />
        </div>
      </ContentRowComponent>

      <StaticImage
        className="first-z-index"
        src="../assets/images/i-am-energy_Support.jpg"
        alt="I am energy"
        layout="fullWidth"
        placeholder="blurred"
      />

      <ContentRowComponent circleConfig={circleConfigs[0]}>
        <div className="text-content-wrapper">
          <div className="orange-box-title width-465">90.10. FAQ</div>
          <div className="text-content-wrapper--headline">
            Lore ipsum dolore
          </div>
          <div className="text-content-wrapper--content">
            Du hast noch Fragen? sadipscing elitr, sed diam nonumy eirmod tempor
            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
            kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
            diam nonumy eirmod tempor invidunt ut labore et dolore magna
            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
            duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
            sanctus est Lorem ipsum dolor sit amet.
          </div>
          <ButtonComponent title={"Zu unseren FAQ`s"} invers />
        </div>
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default SupportPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
